.spin {
    left: 50%;
    top: 55%;
    position: absolute !important;
    z-index: 100 !important;
}

.conversation {
    top: 10%;
    left: 0;
    height: 40vh;
    overflow: auto;
    border: 1px solid rgba(140, 140, 140, 0.35);
    box-shadow: 5px 5px lightgray;
    background-color: white;
    width: 20%;
    position: absolute;
    z-index: 1;
}

.node_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 40px;
}
.node {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 5px;
}
.node_title {
    background-color: #ccc;
    padding: 5px;
    text-align: center;
    font-weight: bold;
}
.divider {
    height: 1px;
    background: black;
    width: 100%;
    min-width: 60px;
}
.condition_position {
    position: absolute;
    height: 20px;
    background-color: white;
    justify-content: center;
    color: black;
    padding: 0 5px;
    box-shadow: 0 0 2px lightgray;
    border-radius: 3px;
}
.divider_line_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    position: relative;
    width: 27vw;
    min-width: 60px;
}
.btn_container {
    display: flex;
    justify-content: center;
}

.documentContainer {
    position: relative;
}

.selectButton {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    visibility: hidden;
}
.documentContainer:hover .selectButton {
    visibility: visible;
}

.checkIcon {
    z-index: 1;
    font-weight: bold;
    color: black;
    font-size: 18px;
    size: 100px;
    padding: 2px;
}

.documentContainerSelected .selectButton {
    visibility: visible;
}
