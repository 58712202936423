.Table {
width: '100%';
background-color: rgba(255, 255, 255, 0.774);
}

.Table Th {
  background-color: black;
  color: white;
  height: 3rem;
  font-size: medium;
}

.Table Tr {
  border-top: 2px solid rgb(240, 236, 236);
  border-bottom: 2px solid  rgb(240, 236, 236);
}

.Table Td {
  text-align: center;
}

.Dropdown {
 visibility: visible;
 color: aliceblue;
}

.SelectDiv {
 visibility: hidden;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 height: 0;
 }

.ant-modal-content{
  width: 30% !important
 }

 .loader {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 45%,
 }

 .notFound {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 45%
}
.mobile {
  visibility: hidden;
  width: 0;
}
.whatsApp {
  visibility: hidden;
  width: 0;

}

 .antBtn {
  float: left ;
  margin-bottom: 10px;
 }

.ReqDetails {
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  padding: "0.5rem",
}

.CSVButton{
 float: right;
}

@media only screen and (max-width: 40em) {
  .ant-modal-content{
    width: 80% !important
  }

 .Dropdown {
  visibility: hidden;
  }

 .SelectDiv {
  visibility: visible;
  margin-bottom: 1rem;
  height: 10vh;
 }
 .notFound {
  left: 35%
}

.mobile {
  visibility: visible;
  margin-left: 20px;
}
.whatsApp {
  visibility: visible;
  margin-left: 4px;

}
.antBtn{
  position:fixed  !important;
  z-index: 100 !important;
  left: 0;
  margin-left: 20px;
}

.ReqDetails {
  padding: 0%;
}

.CSVButton{
  float: none;
  width: 100%;
 }

}