.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    /* height: "100%"; */
    width: "100vh";
}

.spinner {
    position: absolute !important;
    z-index: 1 !important;
    top: 50% !important;
    left: 50% !important;
}

.subHeader {
    color: #202123 !important;
    background-color: #202123 !important;
    padding: 0px 15px !important;
}
