#components-layout-demo-top-side .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(221, 212, 212, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.header {
    position: relative;
}
.menu {
    height: 100%;
}

.company-name-container {
    display: flex;
    margin-right: 30px;
    float: left;
    align-items: center;
    height: inherit;
    cursor: pointer;
}
.select-language {
    display: flex;
    float: right;
    align-items: center;
    margin-right: 30px;
}
.select-language .label {
    color: #060606 !important;
    margin-right: 10px;
    font-size: 15px;
    font: bold;
}

.company-name {
    font-size: 22px !important;
    font-weight: 900 !important;
    color: #060606 !important;
}

.site-layout-background {
    background: #fff;
    height: vh;
}

.ant-layout-header {
    background-color: white !important;
    color: #060606 !important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

.ant-layout-content {
    background-color: white;
}
.ant-menu {
    font-weight: bold !important;
    padding-bottom: 2px !important;
}
@media only screen and (max-width: 40em) {
    .ant-layout-content {
        padding: 0 10px 50px !important;
    }
    .ant-layout-header {
        padding: 0 10px !important;
    }
}
