.stats {
    padding: 2rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
}

.stats-line {
    color: #000;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: normal;
    align-items: center;
}

.cards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.4rem;
    width: calc(100% - 6rem);
    margin: 0 auto;
}

.card {
    width: 23rem;
    justify-content: center;
}

.card-title {
    color: grey;
    font-size: 0.75rem;
    width: 100%;
    height: 2px;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background-color: white;
    border-radius: 2rem;
}

.card-text {
    margin: 0;
    font-size: 1rem;
    color: #000;
}

.card-number {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
}

@media (max-width: 768px) {
    .user-card {
        flex: 1 1 100%;
    }

    .cards {
        width: 100%;
    }
    .card {
        width: 18rem;
    }
}
